import React, {useEffect} from 'react';
import { useSpring } from 'react-spring';
import {useInView} from "react-intersection-observer";
import useMobileDetect from 'hooks/useMobileDetect';
const calc = (x, y) => { return [x - window.innerWidth / 2, y - window.innerHeight / 2]; }

export const translate3d = (x, y, offset = 10, transforms = '') => { return `translate3d(${x / offset}px,${y / offset}px,0) ${transforms}` };

const useMouseMoveAnimate = (props = {}) => {
	const {
		disable = false
	} = props;
	const config = props.config || {
		mass: 10,
		tension: 550,
		friction: 140,
	};
	const { isDesktop } = useMobileDetect();
	const { ref, inView } = useInView({ threshold: 0 });
	const [springProps, setSpring] = useSpring(() => ({ xy: [0, 0], config }))
	const handleMouseMove = ({ clientX: x, clientY: y }) => {
		setSpring({ xy: calc(x, y) })
	}

	useEffect(() => {
		if (isDesktop() && !disable) {
			window.addEventListener('mousemove', handleMouseMove);
		}

		return () => {
			if (isDesktop() &&!disable) {
				window.removeEventListener('mousemove', handleMouseMove);
			}
		}
	}, []);

	return {
		springProps,
		ref,
		inView,
		inViewDesktop: isDesktop() && inView
	};
}

export default useMouseMoveAnimate;
